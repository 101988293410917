const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section featured topspace">
          <h2 class="section-title"><span>βιογραφικο διαιτολογου</span></h2>
          <article class="post">
            <header class="entry-header">
              <h3 class="entry-title">Ελευθερια Παπαχρηστου</h3>
            </header>

            <div class="entry-content lead">
              <img src="assets/images/lowres/diat.jpg" alt="" height={300} style={{ float: "left", marginRight: 20 }} />
              <p>Η Ελευθερία Παπαχρήστου είναι Διαιτολόγος-Διατροφολόγος, απόφοιτη του Τμήματος Επιστήμης Διαιτολογίας- Διατροφής του Χαροκοπείου Πανεπιστημίου και κάτοχος μεταπτυχιακού Διπλώματος με τίτλο «Εφαρμοσμένη Διαιτολογία-Διατροφή: Κατεύθυνση Κλινική Διατροφή». Έχει μετεκπαιδευτεί στην παιδική διατροφή, ολοκληρώνοντας το πρόγραμμα «Τεκμηριωμένη Παιδιατρική Διατροφή» του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης, και στο μητρικό θηλασμό, ολοκληρώνοντας το πρόγραμμα «Μητρικός Θηλασμός: Σεμινάριο για Επαγγελματίες Υγείας» του Ινστιτούτου Υγείας του Παιδιού. Επιπλέον κατέχει πιστοποίηση στη μέθοδο SOS Approach to Feeding για τη διαχείριση παιδιών με επιλεκτική σίτιση.</p>
              <p>Στο πλαίσιο των σπουδών της, έχει ασχοληθεί ερευνητικά με τη διατροφή παιδιών με κυστική ίνωση και τη διατροφή παιδιών με τροφικές αλλεργίες. Επιπλέον, εργάστηκε ως ερευνήτρια στο Πανεπιστήμιο της Γλασκώβης, στο πλαίσιο προγράμματος Erasmus, μελετώντας τη διατροφή και το εντερικό μικροβίωμα με στη νόσο Crohn. Τα τελευταία χρόνια εργάστηκε στο Ινστιτούτο Υγείας του Παιδιού, συμμετέχοντας στο ευρωπαϊκό πρόγραμμα Best-ReMaP για τη διατροφή των παιδιών και παρακολουθώντας βρέφη, παιδιά και εφήβους με ενδογενείς διαταραχές μεταβολισμού (φαινυλκετονουρία και γαλακτοζαιμία).</p>
            </div>
          </article>
        </div>
      </div>
    </main>
  );
}

export default Main;

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section topspace">
          <h2 class="section-title"><span>παιδιατρικο ιατρειο</span></h2>
        </div>

        <div class="row section featured topspace">
          <h2 class="section-title"><span>βιογραφικο</span></h2>
          <article class="post">
            <header class="entry-header">
              <h3 class="entry-title">Αλεξανδρος Σακελλαριου</h3>
            </header>

            <div class="entry-content lead">
              <img src="assets/images/lowres/image1.jpeg" alt="" height={300} style={{ float: "left", marginRight: 20 }} />
              <p>Γεννήθηκε στην Αθήνα αλλά αποφοίτησε με άριστα από το 1ο λύκειο Καρδίτσας. Μέσω πανελληνίων εξετάσεων επέτυχε αρχικά στην Ιατρική σχολή του Πανεπιστημίου Θράκης και έπειτα στην Ιατρική σχολή του Πανεπιστημίου Αθηνών από όπου και αποφοίτησε με «λίαν καλώς». Στη συνέχεια παρακολούθησε το μεταπτυχιακό πρόγραμμα «Κλινική Παιδιατρική και Νοσηλευτική - Έρευνα» της Ά Πανεπιστημιακής Παιδιατρικής Κλινικής του Πανεπιστημίου Αθηνών από το οποίο αποφοίτησε με «άριστα».</p>
              <p>Εργάστηκε στο Αλλεργιολογικό εργαστήριο της Β Πανεπιστημιακής Παιδιατρικής Κλινικής του Πανεπιστημίου Αθηνών συμμετέχοντας σε σημαντικά πανευρωπαϊκά προγράμματα όπως το Europrevall για την επίπτωση της τροφικής αλλεργίας στην Ελλάδα και την Ευρώπη και το Ga2len για την συχνότητα και τη βαρύτητα του άσθματος. Κατά η διάρκεια αυτών παρακολούθησε πρόγραμμα κατάρτισης στην επιδημιολογία στην σχολή δημόσιας υγείας, και ξεκίνησε την διδακτορική του διατριβή πάνω στην συχνότητα της τροφικής αλλεργίας στα παιδιά στην Ελλάδα.</p>
              <p>Ειδικεύθηκε στην παιδιατρική στην Ά παιδιατρική κλινική του νοσοκομείου παίδων «Π&Α Κυριακού» συμμετέχοντας μεταξύ άλλων στα τμήματα αύξησης και ανάπτυξης, διαβητολογικού, γαστρεντερολογικού και λοιμώξεων. Εκπαιδεύτηκε στη νεογνολογία στο μαιευτήριο «Έλενα Βενιζέλου» και στην παιδιατρική αλλεργιολογία στο St. Mary’s Hospital του Λονδίνου με υποτροφία από την Ευρωπαϊκή Ακαδημία Αλλεργιολογίας.</p>
              <p>Εκπαιδεύτηκε περαιτέρω στην διατροφή των παιδιών, καθώς έχει αποφοιτήσει με «Άριστα» από το μεταπτυχιακό πρόγραμμα Παιδιατρικής Διατροφής (Pediatric Nutrition) της Ιατρικής Σχολής του Πανεπιστημίου της Βοστώνης. Επίσης με άριστα από το μεταπτυχιακό πρόγραμμα Early Nutrition Specialist της Ιατρικής Σχολής του Πανεπιστημίου του Μονάχου και Early Nutrition Academy του ίδιου πανεπιστημίου.</p>
              <p>Τα τελευταία χρόνια ασχολείται ιδιαίτερα με την εφηβική ιατρική έχοντας ολοκληρώσει το πρόγραμμα εκπαίδευσης με τίτλο: «Εφηβική Ιατρική: ψυχοσωματική και γνωστική ανάπτυξη, αναπαραγωγική υγεία, διατροφή, άθληση και στρες των εφήβων». εμβαθύνοντας σε θέματα ψυχολογίας και διατροφής ενώ έχει λάβει μέρος και στο πρόγραμμα κατάρτισης: παιδιά/έφηβοι στις ημέρες COVID 19 κοινωνική/συναισθηματική ενδυνάμωση & ψυχική ανθεκτικότητα. Έχει εργαστεί σαν ιατρός σε αθλητικές εγκαταστάσεις (γήπεδα ποδοσφαίρου, αγώνες δρόμου μεγάλων αποστάσεων, κολυμβητικές ομάδες) σε διάφορες μονάδες κατά τη διάρκεια της στρατιωτικής του θητείας, όπου έχει παρακολουθήσει και μαθήματα πρώτων βοηθειών (Advance Life Support και Advanced Trauma Life Support) καθώς και σε τουριστικές και κατασκηνωτικές μονάδες. Έχει συμμετοχή στην μετάφραση πολλών ιατρικών συγγραμμάτων μεταξύ των οποίων και στο σύγγραμμα παιδιατρικής του Nelson.</p>
              <p>Μετά το τέλος της ειδικότητας ξεκίνησε να εργάζεται στο ιατρείο του στο περιστέρι και στην αθηναϊκή κλινική για πάνω από 3 χρόνια. Ταυτόχρονα εξυπηρετούσε τις ανάγκες κατ’ οίκον επισκέψεων στο δίκτυο του ομίλου βιοϊατρικής. Από το 2013 εξυπηρετεί το δίκτυο ασφαλισμένων της interamerican μέσω της γραμμής υγειάς 1010.</p>
              <p>Έχει συμμετάσχει με παρουσιάσεις και διαλέξεις σε περισσότερες από 20 ημερίδες και συνέδρια και έχει περισσότερες από 10 δημοσιεύσεις του σε ελληνικά και διεθνή περιοδικά, ενώ κατά καιρούς αρθρογραφεί και σε έντυπα ιατρικού ενδιαφέροντος του ομίλου interamerican. Στο παρελθόν παρείχε συμβουλές για θέματα παιδιατρικής μέσω της πρωινής ζώνης του ραδιοφωνικού σταθμού «Αθήνα 98,4».</p>
              <p>Γνωρίζει άριστα Αγγλικά ενώ έχει παρακολουθήσει στο παρελθόν μαθήματα Σουηδικών και Ισπανικών.</p>
              <p>Συμμετέχει σε μελέτες όπως HerA και armadillo σε συνεργασία με το Χαροκόπειο Πανεπιστήμιο και συμμετέχει σε εκπαιδευτικό πρόγραμμα για τις επιπτώσεις της κλιματικής αλλαγής στην παιδιατρική. Παρακολουθεί το πρόγραμμα early immunity and nutrition από το πανεπιστήμιο του Μονάχου για τη σύνδεση της διατροφής με την ανοσία. Είναι παντρεμένος από το 2011 με την αλλεργιολόγο ΝΤΙΑΝΑ ΙΒΑΝΟΒΑ και έχουν δύο υπέροχα, ζωηρά και σκανδαλιάρικα αγόρια τον Γεώργιο και τον Δημήτριο.</p>
            </div>
          </article>
        </div>
      </div>
    </main>
  );
}

export default Main;

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section featured topspace">
          <h2 class="section-title"><span>βιογραφικο ψυχολογου</span></h2>
          <article class="post">
            <header class="entry-header">
              <h3 class="entry-title">Χριστινα Μαρινα Μπιλιρακη</h3>
            </header>

            <div class="entry-content lead">
              <img src="assets/images/lowres/IMG-20240228-WA0000.jpg" alt="" height={300} style={{ float: "left", marginRight: 20 }} />
              <p>Η Χριστίνα Μαρίνα Μπιλιράκη είναι Ιατρική Ψυχολόγος/Ψυχολόγος Υγείας. Σπούδασε ψυχολογία (BSc Honours) στο University of Surrey του Ηνωμένου Βασιλείου και πραγματοποίησε την πρακτική της άσκηση στο Πανεπιστημιακό Νοσοκομείο Giessen και Marburg της Γερμανίας στο Κέντρο Κοινωνικής Παιδιατρικής. Τα δεδομένα που συλλέχθηκαν για την διπλωματική της εργασία έχουν δημοσιευθεί στο επιστημονικό περιοδικό Appetite με τον εαυτό της ως συν-συγγραφέα.</p>
              <p>Πραγματοποίησε μεταπτυχιακές σπουδές στην Ιατρική Ψυχολογία και Ψυχολογία της υγείας (MSc) στο Leiden University της Ολλανδίας. Ολοκλήρωσε επιτυχώς την πρακτική της άσκηση στην Δ’ Ογκολογική Κλινική του Metropolitan Hospital.</p>
              <p>Από το 2023, ειδικεύεται στις Γνωσιακές Ψυχοθεραπείες στην Ελληνική Εταιρεία Γνωσιακών Ψυχοθεραπειών με την πιστοποίηση EABCT. Επιπλέον, πραγματοποιεί την κλινική της εκπαίδευση, στο Κέντρο Ψυχοθεραπειών του Αιγινητείου Νοσοκομείου.</p>
              <p>Παράλληλα εργάζεται ως ψυχολόγος σε Κέντρο Ημέρας Υποστήριξης Εργαζομένων.</p>
              <p>Είναι κάτοχος άδειας ασκήσεως επαγγέλματος και αποτελεί μέλος της Ελληνικής Εταιρείας Γνωσιακών Ψυχοθεραπειών και της The British Psychological Society.</p>
            </div>
          </article>
        </div>
      </div>
    </main>
  );
}

export default Main;

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section topspace">
          <h2 class="section-title"><span>αλλεργιολογικο ιατρειο</span></h2>
        </div>

        <div class="row section featured topspace">
          <h2 class="section-title"><span>βιογραφικο</span></h2>
          <article class="post">
            <header class="entry-header">
              <h3 class="entry-title">Ντιανα Ιβανοβα</h3>
            </header>

            <div class="entry-content lead">
              <img src="assets/images/lowres/image2.jpeg" alt="" height={300} style={{ float: "left", marginRight: 20 }} />
              <p>Γεννήθηκα και μεγάλωσα στο Kazanlaκ Βουλγαρίας, μια όμορφη μικρή πόλη στην Κοιλάδα των Ρόδων. Μαθήτευσα στο Γαλλικό κολλέγιο Romain Rollan Stara Zagora το οποίο τελείωσα με άριστα 9,53. Έπειτα σπούδασα στην Ιατρική σχολή του TRACIAN UNIVERCITY στην ίδια πόλη και αποφοίτησα με άριστα 9,67.</p>
              <p>Ακολουθώντας το όνειρο μου να γίνω αλλεργιολόγος ήρθα στην Ελλάδα και ξεκίνησα την ειδικότητα μου στην 1η Κρατική Παθολογική Κλινική, στο Ιπποκράτειο νοσοκομείο Αθηνών και έπειτα στο Αλλεργιολογικό τμήμα Λαϊκού Νοσοκομείου, στην Αθήνα. Κατά την διάρκεια της εκπαίδευσης μου εργάστηκα στο τμήμα πνευμονικών δοκιμασιών στο Γενικό Νοσοκομείο Νοσημάτων Θώρακος «Η Σωτηρία», στο δερματολογικό τμήμα του Νοσοκομείο Ανδρέας Συγγρός, στο ανοσολογικό τμήμα Γενικού Νοσοκομείου Αθηνών «Γεώργιος Γεννηματάς» καθώς και στο Αλλεργιολογικό τμήμα στο Νοσοκομείο Παίδων Παναγιώτη & Αγλαΐας Κυριακού.</p>
              <p>Έχω παρακολουθήσει και έχω λάβει πιστοποίηση σε ECDL Πρόγραμμα εκμάθησης Χειρισμού Ηλεκτρονικών Υπολογιστών, Σεμινάριο Στατιστικών Προγραμμάτων Ανάλυσης (SPSS), Σεμινάριο Μαστοκυττάρωσης, Αττικό Νοσοκομείο, Μεταπτυχιακό προγραμμά Παιδιατρικής διατροδής της Ιατρικής Σχολής του Πανεπιστημίου της Βοστώνης (ΗΠΑ) Early Nutrion Boston University.</p>
              <p>Παρακολουθώ και κατά καιρούς πραγματοποιώ ομιλίες σε Πανελλήνια και Πανευρωπαικά Αλλεργιολογικά Συνέδρια ενώ εργασίες μου έχουν δημοσιευτεί σε διεθνή επιστημονικά περιοδικά. Έχω μεταφράσει κείμενα στο Ατλαντά Αλλεργιολογίας της Ευρωπαϊκής Αλλεργιολογικής εταιρίας.</p>
              <p>Η αγάπη μου για τις ξένες γλώσσες ξεκίνησε από τα σχολικά μου χρόνια και έχω πολύ καλή γνώση στα γαλλικά, αγγλικά, βουλγάρικα, ρώσικα και παρακολουθώ μαθήματα ισπανικών.</p>
              <p>Εργάστηκα στο ιδιωτικό διαγνωστικό κέντρο Ιατρική Μερίμνα Α.Ε Πετρούπολής, εφημέρευα στην Ιδιωτική Κλινική Δυτικής Αθήνας, ήμουν ιατρός αγώνων ποδοσφαίρου και λοιπών αθλητικών εκδηλώσεων Ε.Π.Σ.Α.Ν.Α και τέλεσα ως ιατρός της ομάδας πόλο Γ.Σ.Π Περιστερίου. Είμαι εξωτερικός συνεργάτης από το 2013 του ομίλου Ιατρικό Αθηνών, υποκατάστημα Περιστερίου και ιατρός ελεγκτής του ταμείου ΤΕΑ-ΥΠΟΙΚ από το 2007. Από 2014 είμαι μέλος του ΔΣ την ΕΕΑΚΑ - ΕΛΛΗΝΙΚΗΣ ΕΤΑΙΡΕΙΑΣ ΑΛΛΕΡΓΙΟΛΟΓΙΑΣ ΚΑΙ ΚΛΙΝΙΚΗΣ ΑΝΟΣΟΛΟΓΙΑΣ, και ως σήμερα γραμματέας στη ΠΕΑ - ΠΑΝΕΛΛΗΝΙΑ ΕΝΩΣΗ ΑΛΛΕΡΓΙΟΛΟΓΩΝ.</p>
              <p>Τον ελεύθερο μου χρόνο τον αφιερώνω στην οικογένεια μου, μου αρέσουν πολύ τα ταξίδια, η γυμναστική, το θέατρο, ο κινηματόγραφος, οι βόλτες στην φύση και η μαγειρική.</p>
              <p>Από το 2011 είμαι παντρεμένη με τον σύζυγο μου Αλέξανδρό Σακελλαρίου Παιδίατρός και αποκτήσαμε δύο υπέροχα αγόρια. Από το 2010 διατηρώ ιδιωτικό ιατρείο στο Περιστέρι, όπου προσφέρω τις υπηρεσίες μου σε παιδία και ενήλικες.</p>
            </div>
          </article>
        </div>
      </div>
    </main>
  );
}

export default Main;

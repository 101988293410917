// import Card from './components/Card';
import Grid from '@mui/material/Grid';

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section featured topspace">
          <h2 class="section-title"><span>ενημερωτικο υλικο</span></h2>
          <Grid container>

          </Grid>
        </div>
      </div>
    </main>
  );
}

export default Main;
